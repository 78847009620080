function TermsAndConditions() {
    return (
        <section style={{ backgroundColor: "#fdcb32" }}>
            <div className="container p-t80 p-b80">
                <div className="terms-content" style={{ color: "black" }}>
                    <h2>Terms and Conditions</h2>

                    <h3>1. These Conditions</h3>
                    <p>These Conditions shall, together with the Proposal issued to You by Us describing the Services, constitute the whole agreement between You and First Page Rankerz.</p>

                    <h3>2. The Services</h3>
                    <p>2.1. We will provide the Services to You during the Term.</p>
                    <p>2.2. For Us to provide the level of service proposed we will require input from You to gather information about your business, products and services relevant to the Services. You shall devote such time as is reasonably necessary to provide this information in a timely manner upon request and will make a suitable manager or another senior employee available when reasonably necessary to facilitate the orderly flow of requests and information between Us and You.</p>

                    <h3>3. Payment Terms</h3>
                    <p>During the Term, You will pay the Charges on the dates specified. Monthly invoices will be sent 30 days in advance of each payment date. And the payment will be made to First Page Rankerz under the umbrella of Maple Logistics.</p>

                    <h3>4. Consequences for Late Payment</h3>
                    <p>4.1. If our Charges are not settled in accordance with our payment terms then, without prejudice to any other rights we may have, we reserve the right to suspend performance of the Services until payment is received in full. Prompt payment is therefore required in order that no disruption to the Services provided by Us takes place.</p>

                    <h3>5. Disclosure and Intellectual Property</h3>
                    <p>5.1. Neither party shall at any time disclose to any third party any confidential information of the other party which is acquired prior to or during the term of this Agreement.</p>
                    <p>5.2. All parties acknowledge that each party owns the Intellectual Property rights of their own brand and any designer content created by them.</p>

                    <h3>6. Term and Termination</h3>
                    <p>6.1. This Agreement shall come into force on the date of receipt of the Initial Payment.</p>
                    <p>6.2. After the Initial Term this Agreement shall renew automatically for successive Additional Terms, unless You cancel the provision of the Services in accordance with the notice requirements, or this agreement is terminated earlier in accordance with these Conditions.</p>

                    <h3>7. Cancellation of Services by You</h3>
                    <p>7.1. In the event that You wish to cancel receipt of the Services provided by Us, You must give Us notice of your intention to do so. You must give Us notice at least 30 days before the Initial Term or Additional Term (as applicable) ends. If You do not give sufficient notice to cancel the Services, an Additional Term will automatically apply, so it is important that You give notice in good time.</p>
                    <p>7.2. You can give notice verbally to your account manager, but it must be confirmed in writing by email to technicalsupport@firstpagerankerz.com. If You receive an automated response that indicates that we may not have received your email, it is important that You try again or send Us a letter in the post to our registered office.</p>

                    <h3>8. Cancellation or Suspension of Services by Us</h3>
                    <p>8.1. We can suspend the provision of the Services if You do not meet any of your payment obligations (whether as to the amounts or dates of payment) or we can cancel this Agreement by giving You written notice.</p>
                    <p>8.2. A cancellation notice issued by Us will be effective immediately, and we shall be entitled (but not required) to issue you with an invoice for the balance of the Charges that would have fallen due over the remainder of the Initial Term or Additional Term (as applicable).</p>

                    <h3>9. Transfer of the Site(s) receiving the Services</h3>
                    <p>9.1. If the ownership of the Site(s) receiving the Services transfers to another business, You will remain liable for the Agreed Budget Charges for the remainder of the Term unless we consent to the novation of this Agreement to the new owner.</p>
                    <p>9.2. If You notify Us that You have closed the Site(s) receiving the Services and no longer have an interest in receiving the Services, we may (at our discretion) terminate this Agreement with immediate effect. If we do so, we may issue You with an invoice for the balance of the Charges that would have fallen due over the remainder of the Initial Term or Additional Term (as applicable).</p>

                    <h3>10. Our Liability</h3>
                    <p>10.1. We shall not be liable to You for any damage to software, damage to or loss of data, loss of profit, anticipated profits, revenues, anticipated savings, goodwill or business opportunity, or for any indirect or consequential loss or damage.</p>
                    <p>10.2. Our aggregate liability to You in respect of any claims based on events in any period arising out of or in connection with this Agreement (or any related contract including website design or hosting), whether in contract or tort (including negligence) or otherwise, shall in no circumstances exceed 100% of the total Charges actually paid by You to Us under this Agreement and any related contract in that period.</p>
                    <p>10.3. Nothing in this Agreement shall operate to exclude or limit our liability for:</p>
                    <p>a. death or personal injury caused by our negligence;</p>
                    <p>b. fraud;</p>
                    <p>c. any other liability which cannot be excluded or limited under American law.</p>

                    <h3>11. Bankruptcy</h3>
                    <p>In the event of either party becoming bankrupt or insolvent or committing any act of bankruptcy or insolvency or going into liquidation or in the event that a Receiver or Administrator or Administrative Receiver is appointed in respect of any of its assets, the other party shall have the right to terminate this contract with immediate effect with no further liability (save for any accrued rights of action or damages due to them on the date of termination) to the other party.</p>

                    <h3>12. Data Protection</h3>
                    <p>12.1. We will both comply with all applicable requirements of the US Data Protection Legislation and the General Data Protection Regulation and any other directly applicable regulation relating to privacy (“Data Protection Legislation”). This Condition is in addition to, and does not relieve, remove or replace, either of our obligations under the Data Protection Legislation.</p>
                    <p>12.2. The provisions to our Data Protection Terms and Conditions, as amended from time to time, shall apply to this agreement as if they were set out in full to these Conditions.</p>

                    <h3>13. Governing Law and Jurisdiction</h3>
                    <p>This agreement and any dispute or claim arising out of or in connection with it or its formation, including non-contractual disputes or claims, shall be governed by American Law, and the parties submit to the exclusive jurisdiction of the courts of United States to settle any such dispute or claim.</p>

                </div>
            </div>
        </section>
    );
}

export default TermsAndConditions;